import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, TeamMemberCard } from '../../../components';

import styles from './TeamGrid.module.css';

export const TeamGrid = ({ members, applicationInfo, className }) => {
    const membersGrid = useMemo(() => members.map((member, index) => (
        <TeamMemberCard
            slug={ member.slug }
            name={ member.name }
            role={ member.role }
            key={ `member-${index}` }
            className={ styles.card }
            area={ member.practiceArea }
            photoUrl={ member.photo.url }
            photoAlt={ member.photo.description } />
    )), [members]);

    return (
        <div className={ classNames(styles.grid, className) }>
            { membersGrid }
            { applicationInfo && <div className={ styles.banner }>
                <div className={ styles.wrapper }>
                    <p className={ styles.text }>{ applicationInfo.text }</p>
                    <Button
                        asAnchor
                        to={ `mailto:${applicationInfo.email}?subject=${applicationInfo.emailSubject}&body=${applicationInfo.emailText}` }
                        className={ styles.link }>
                        { applicationInfo.buttonText }
                    </Button>
                </div>
            </div>}
        </div>
    );
};

TeamGrid.propTypes = {
    className: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.shape({
        photoUrl: PropTypes.string,
        name: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        practiceArea: PropTypes.object.isRequired,
    })).isRequired,
    applicationInfo: PropTypes.shape({
        text: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        emailText: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired,
        emailSubject: PropTypes.string.isRequired,
    }),
};

export default TeamGrid;
