import React from 'react';
import PropTypes from 'prop-types';

import TeamGrid from './team-grid';
import {
    Layout,
    Observer,
    RichTextToHtml,
} from '../../components';

import styles from './Team.module.css';

export const Team = ({
    data: {
        contentful: {
            teamPageCollection: {
                items: [page],
            },
        },
    },
    location,
}) => {
    const {
        tabTitle,
        heroText,
        descriptionText,
        applicationText,
        applicationEmail,
        applicationEmailText,
        applicationButtonText,
        applicationEmailSubject,
        membersCollection: { items: members },
    } = page;

    const applicationInfo = {
        text: applicationText,
        email: applicationEmail,
        emailText: applicationEmailText,
        buttonText: applicationButtonText,
        emailSubject: applicationEmailSubject,
    };

    const seoData = {
        title: `${tabTitle} - PA Advogados`,
    };

    return (
        <Layout
            seoData={ seoData }
            location={ location }>
            <Observer threshold={ 0.3 }>
                { (isVisible) => (
                    <section className={ styles.hero }>
                        <h1 className={ styles.heading }>{ tabTitle }</h1>
                        <RichTextToHtml
                            withTextAnimation
                            startAnimation={ isVisible }>
                            { heroText.json }
                        </RichTextToHtml>
                    </section>
                ) }
            </Observer>
            <section className={ styles.team }>
                <TeamGrid
                    members={ members }
                    applicationInfo={ applicationInfo } />
            </section>
            <Observer threshold={ 0.3 }>
                { (isVisible) => (
                    <section className={ styles.teamDetails }>
                        <RichTextToHtml
                            withTextAnimation
                            startAnimation={ isVisible }>
                            { descriptionText.json }
                        </RichTextToHtml>
                    </section>
                ) }
            </Observer>
        </Layout>
    );
};

Team.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            teamPageCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Team;
